import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";
import Baja from "../Baja";

function MaquilasSemanal() {
  const { user } = useContext(AuthContext);
  const URL_ARTICULOS_PROCESOS_SALIDA = process.env.REACT_APP_URL_ARTICULOS_PROCESOS_SALIDA;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 7);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  // const [maquileros, setMaquileros] = useState([]);
  // const [selectedMaquilero, setSelectedMaquilero] = useState("");
  // const [selectedVencido, setSelectedVencido] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(
      `${URL_ARTICULOS_PROCESOS_SALIDA}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then((response) => {
      let allProcesos = response.data;
      let arrayTabla = allProcesos
        .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id: a._id,
            pedido: a.pedidos[0].idPedido, // pedido es un número
            fechaVencimiento: a.pedidos[0].fecha_cancelacion,
            modelo: a.pedidos[0].articulos[0].codigo + " / " + a.pedidos[0].articulos[0].codigoCliente + " / " + a.pedidos[0].articulos[0].nombre,
            fecha: a.procesosSalida[0].fecha,
            fechaCompromiso: a.procesosSalida[0].fechaCompromiso,
            maquilero: a.procesosSalida[0].proveedores[0].nombre_comercial,
            cantidad: a.cantidadSalida,
          };
        })
        .filter(function (el) {
          return el != null;
        });
  
      let dataFinal = Object.values(arrayTabla);
  
      // Cambiamos `groups` para ser un objeto
      let agrupado = dataFinal.reduce(function (groups, item) {
        const val = item.pedido; // Usamos pedido como la clave de agrupación
        // Si el grupo no existe, lo inicializamos
        if (!groups[val]) {
          groups[val] = {
            pedido: item.pedido,
            fechaVencimiento: item.fechaVencimiento,
            modelo: item.modelo,
            fecha: item.fecha,
            fechaCompromiso: item.fechaCompromiso,
            maquilero: item.maquilero,
            cantidad: 0,
          };
        }
        // Sumamos la cantidad
        groups[val].cantidad += item.cantidad;
  
        return groups;
      }, {});
  
      // Convertimos el objeto `agrupado` de nuevo en un array si es necesario
      let arrayAgrupado = Object.values(agrupado);
      console.log("agrupado", arrayAgrupado);
  
      setComments(arrayAgrupado);
    })
    .catch((err) => {
      console.log(err);
    });
    // axios
    //   .get(`${URL_PROVEEDORES}Maquileros`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allMaquileros = response.data;
    //     setMaquileros(allMaquileros);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  console.log("hola", comments)
  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) 
        // &&
        // (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        total = total + c.cantidad;
        return [
          c.fecha,
          c.pedido,
          c.modelo,
          c.fechaVencimiento,
          c.maquilero,
          new Intl.NumberFormat("en-US").format(c.cantidad),
          c.fechaCompromiso
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Procesos Pendientes", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Pedido",
          "Modelo",
          "Fecha Vencimiento",
          "Maquilero",
          "Cantidad",
          "Fecha Compromiso",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("MaquilasSemanl.pdf");
  }

  function excel() {
    const dataExcel = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
        //  &&
        // (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        return {
          Fecha: c.fecha,
          Pedido: c.pedido,
          Modelo: c.modelo,
          FechaVencimiento: c.fechaVencimiento,
          Maquilero: c.maquilero,
          Cantidad: c.cantidad,
          FechaCompromiso: c.fechaCompromiso,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `MaquilasSemanal-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `MaquilasSemanal`,
        sheetFilter: [
          "Fecha",
          "Pedido",
          "Modelo",
          "Fecha Vencimiento",
          "Maquilero",
          "Cantidad",
          "Fecha Compromiso",
        ],
        sheetHeader: [
          "Fecha",
          "Pedido",
          "Modelo",
          "Fecha Vencimiento",
          "Maquilero",
          "Cantidad",
          "Fecha Compromiso",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) 
        // &&
        // (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        total = total + c.cantidad;
        return [
          c.fecha,
          c.pedido,
          c.modelo,
          c.fechaVencimiento,
          c.maquilero,
          c.cantidad,
          c.fechaCompromiso
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Procesos Pendientes", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Pedido",
          "Modelo",
          "Fecha Vencimiento",
          "Maquilero",
          "Cantidad",
          "Fecha Compromiso",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Maquilas Semanal",
          email: mailTo,
          fileName: "MaquilasSemanal.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la Maquilas Semanal.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Pedido", field: "pedido", sortable: false },
    { name: "Modelo", field: "modelo", sortable: true },
    { name: "Fecha Vencimiento", field: "fechaVencimiento", sortable: true },
    { name: "Maquilero", field: "maquilero", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Fecha Compromiso", field: "fechaCompromiso", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.modelo.toLowerCase().includes(search.toLowerCase()) ||
          comment.maquilero.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedido.toString().toLowerCase().includes(search.toLowerCase()) || // Convertimos pedido a string
          comment.cantidad.toString().includes(search)
      );
    }

    // if (selectedMaquilero) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMaquilero.includes(selectedMaquilero)
    //   );
    // }

    // if (selectedVencido) {
    //   if (selectedVencido == "vencido") {
    //     computedComments = computedComments.filter(
    //       (e) => e.fechaCompromiso < endDate
    //     );
    //   }
    //   if (selectedVencido == "aTiempo") {
    //     computedComments = computedComments.filter(
    //       (e) => e.fechaCompromiso > compromiso8
    //     );
    //   }
    //   if (selectedVencido == "porVencer") {
    //     computedComments = computedComments.filter(
    //       (e) => e.fechaCompromiso < compromiso8 && e.fechaCompromiso > endDate
    //     );
    //   }
    // }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    // selectedMaquilero,
    // selectedVencido,
  ]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    <Button
                      size="sm"
                      href="/MenuReportes"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Maquilas Semanal</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Maquilas Semanal</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.cantidad;
                      return (
                        <tr
                          // style={
                          //   c.fechaCompromiso < endDate
                          //     ? { backgroundColor: "#ed8c8c" }
                          //     : c.fechaCompromiso > compromiso8
                          //     ? { backgroundColor: "white" }
                          //     : { backgroundColor: "orange" }
                          // }
                        >
                          <td>{c.fecha}</td>
                          <td>{c.pedido}</td>
                          <td>{c.modelo}</td>
                          <td>{c.fechaVencimiento}</td>
                          <td>{c.maquilero}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(c.cantidad)}
                          </td>
                          <td>{c.fechaCompromiso}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US").format(totalTabla)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default MaquilasSemanal;
