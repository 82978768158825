import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import ListadoUsuariosAdmin from '../components/layout/Login/ListadoUsuariosAdmin'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// {/* Catalogos */}
import BotonesCatalogos from "./views/Catalogos/BotonesCatalogos"
import ListadoPeriodosAdmin from './views/Catalogos/PeriodosAdmin/ListadoPeriodosAdmin'
import ListadoAreas from './views/Catalogos/Areas/ListadoAreas'
import ListadoDepartamentos from './views/Catalogos/Departamentos/ListadoDepartamentos'
import PeriodosAdminCreate from './views/Catalogos/PeriodosAdmin/PeriodosAdminCreate'
import ListadoPlanteles from './views/Catalogos/Planteles/ListadoPlanteles'
import ListadoCiclos from './views/Catalogos/Ciclos/ListadoCiclos'
import ListadoProveedores from './views/Catalogos/Proveedores/ListadoProveedores'
import ProveedoresCreate from './views/Catalogos/Proveedores/ProveedoresCreate'

// Materiales
import BotonesMateriales from "./views/Materiales/BotonesMateriales";
import ListadoColores from './views/Materiales/Colores/ListadoColores'
import ListadoTiposTelas from './views/Materiales/TiposTelas/ListadoTiposTelas'
import ListadoTelas from './views/Materiales/Telas/ListadoTelas'
import TelasCreate from './views/Materiales/Telas/TelasCreate'
import ListadoHabilitacion from './views/Materiales/Habilitacion/ListadoHabilitacion'
import HabilitacionCreate from './views/Materiales/Habilitacion/HabilitacionCreate'
import ListadoInventariosTelas from './views/Materiales/Telas/ListadoInventariosTelas'
import ListadoInventariosHabilitacion from "./views/Materiales/Habilitacion/ListadoInventariosHabilitacion";

// {/* Articulos */}
import BotonesArticulos from "./views/Articulos/BotonesArticulos"
import ListadoArticulos from './views/Articulos/Articulos/ListadoArticulos'
import ArticulosCreate from './views/Articulos/Articulos/ArticulosCreate'
import ListadoLineas from './views/Articulos/Lineas/ListadoLineas'
import ListadoTemporadas from './views/Articulos/Temporadas/ListadoTemporadas'
import ListadoColecciones from './views/Articulos/Colecciones/ListadoColecciones'
import ListadoTallas from "./views/Articulos/Tallas/ListadoTallas";
import TallasCreate from "./views/Articulos/Tallas/TallasCreate";

// {/* Inventarios */}
import BotonesInventarios from "./views/Inventarios/BotonesInventarios"
import ListadoDisponible from "./views/Inventarios/InventariosTelas/ListadoDisponible";
import ListadoSeguimiento from "./views/Inventarios/InventariosTelas/ListadoSeguimiento";
import ListadoDisponibleVendedor from "./views/Inventarios/InventariosTelas/ListadoDisponibleVendedor";
import ListadoPendRecibir from "./views/Inventarios/InventariosTelas/ListadoPendRecibir";
import ListadoInventarios from "./views/Inventarios/Inventarios/ListadoInventarios";

// {/* Compras */}
import BotonesCompras from "./views/Compras/BotonesCompras"
import ListadoOrdenesCompra from './views/Compras/OrdenesCompra/ListadoOrdenesCompra'
import OrdenesCompraCreate from './views/Compras/OrdenesCompra/OrdenesCompraCreate'
import ListadoCompras from "./views/Compras/Compras/ListadoCompras";
import ComprasCreate from "./views/Compras/Compras/ComprasCreate";
import ListadoDevolucionesProveedores from "./views/Compras/DevolucionesProveedores/ListadoDevolucionesProveedores";
import DevolucionesProveedoresCreate from "./views/Compras/DevolucionesProveedores/DevolucionesProveedoresCreate";

// Produccion
import BotonesProduccion from "./views/Produccion/BotonesProduccion"
import ListadoMaquileros from "./views/Produccion/Maquileros/ListadoMaquileros";
import MaquilerosCreate from "./views/Produccion/Maquileros/MaquilerosCreate";
import ListadoCatalogoProcesos from "./views/Produccion/CatalogoProcesos/ListadoCatalogoProcesos";
import CatalogoProcesosCreate from "./views/Produccion/CatalogoProcesos/CatalogoProcesosCreate";
import ListadoOrdenesCorte from "./views/Produccion/OrdenesCorte/ListadoOrdenesCorte";
import OrdenesCorteCreate from "./views/Produccion/OrdenesCorte/OrdenesCorteCreate";
import ListadoCortes from "./views/Produccion/Cortes/ListadoCortes";
import CortesCreate from "./views/Produccion/Cortes/CortesCreate";
import CortesPendientesRecibir from "./views/Produccion/Cortes/CortesPendientesRecibir";
import ListadoApartados from './views/Produccion/Apartados/ListadoApartados'
import ApartadosCreate from './views/Produccion/Apartados/ApartadosCreate'


// {/* Ventas */}
import BotonesVentas from "./views/Ventas/BotonesVentas"
import ListadoPedidos from './views/Ventas/Pedidos/ListadoPedidos'
import PedidosCreate from './views/Ventas/Pedidos/PedidosCreate'
import ListadoSurtidos from './views/Ventas/Surtidos/ListadoSurtidos'
import ListadoPendienteSurtir from "./views/Ventas/Surtidos/ListadoPendienteSurtir";
import ListadoDevoluciones from './views/Ventas/Devoluciones/ListadoDevoluciones'
import DevolucionesCreate from './views/Ventas/Devoluciones/DevolucionesCreate'

// {/* Admin */}
import BotonesGastos from "./views/ConfigAdmin/BotonesGastos"
import ListadoPresupuestos from './views/ConfigAdmin/Presupuestos/ListadoPresupuestos'
import PresupuestosCreate from './views/ConfigAdmin/Presupuestos/PresupuestosCreate'
import ListadoGastos from './views/ConfigAdmin/Gastos/ListadoGastos'
import GastosCreate from './views/ConfigAdmin/Gastos/GastosCreate'
import ListadoConceptosGastos from './views/ConfigAdmin/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/ConfigAdmin/ConceptosGastos/ConceptosGastosCreate'

//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoNominas from "./views/Empleados/Nominas/ListadoNominas"
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoFaltasColaboradores from './views/Empleados/Faltas/ListadoFaltasColaboradores'
import FaltasColaboradoresCreate from './views/Empleados/Faltas/FaltasColaboradoresCreate'
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import NominasCreate from "./views/Empleados/Nominas/NominasCreate"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"

// Clientes
import BotonesClientes from './views/Clientes/BotonesClientes'
import BotonesCatalogosFacturas from './views/Clientes/Facturas/Catalogos/BotonesCatalogosFacturas'
import ListadoClientes from "./views/Clientes/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/Clientes/ClientesCreate"
import FacturasCreate from "./views/Clientes/Facturas/FacturasCreate"
import ListadoFacturas from "./views/Clientes/Facturas/ListadoFacturas"
import ListadoDetalleFacturas from "./views/Clientes/Facturas/ListadoDetalleFacturas"
import ListadoGrupos from './views/Clientes/Facturas/Catalogos/Grupos/ListadoGrupos'
import AbonosFacturasCreate from "./views/Clientes/Facturas/AbonosFacturasCreate"
import ListadoAbonosFacturas from './views/Clientes/Facturas/ListadoAbonosFacturas'
import ListadoCobranza from "./views/Clientes/Facturas/ListadoCobranza"


// Edo Cta Clientes
import EdoCtaVistaClientes from './views/Clientes/Clientes/LoginCliente/EdoCtaVistaCliente'

// Logs
import Logs from './views/Logs/ListadoLogs'
// Config
import Configuraciones from './views/Configuracion/Configuraciones'

// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import AbonosCreate from './views/Admin/Abonos/AbonosCreate'
import ListadoAbonos from './views/Admin/Abonos/ListadoAbonos'
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ListadoVentas from "./views/Admin/Ventas/ListadoVentas";
import NotasCargoCreate from './views/Admin/NotasCargo/NotasCargoCreate'
import ListadoNotasCargo from './views/Admin/NotasCargo/ListadoNotasCargo'
import ListadoDevolucionesAdmin from './views/Admin/DevolucionesAdmin/ListadoDevolucionesAdmin'
import ListadoConceptosBonificaciones from "./views/Admin/ConceptosBonificaciones/ListadoConceptosBonificaciones";
import BonificacionesCreate from "./views/Admin/Bonificaciones/BonificacionesCreate";
import ListadoBonificaciones from "./views/Admin/Bonificaciones/ListadoBonificaciones";

// Reportes
import BotonesReportes from "./views/Reportes/BotonesReportes";
import CorteSemanal from "./views/Reportes/CorteSemanal";
import MaquilasSemanal from "./views/Reportes/MaquilasSemanal";
import EntradasSemanal from "./views/Reportes/EntradasSemanal";
import EntregasSemanal from "./views/Reportes/EntregasSemanal";

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import EstadoCuentaMaq from "./views/CXP/EstadoCuentaMaq/EstadoCuentaMaq";
import ListadoComprasCXP from "./views/CXP/ComprasCXP/ListadoComprasCXP";
import AbonosProveedoresCreate from './views/CXP/AbonosProveedores/AbonosProveedoresCreate'
import ListadoAbonosProveedores from './views/CXP/AbonosProveedores/ListadoAbonosProveedores'
import ListadoDevolucionesProveedoresAdmin from './views/CXP/DevolucionesProveedoresAdmin/ListadoDevolucionesProveedoresAdmin'
import ListadoNotasCxP from "./views/CXP/NotasCxP/ListadoNotasCxP";
import NotasCxPCreate from "./views/CXP/NotasCxP/NotasCxPCreate";
import AbonosMaquilerosCreate from './views/CXP/AbonosMaquileros/AbonosMaquilerosCreate'
import ListadoAbonosMaquileros from './views/CXP/AbonosMaquileros/ListadoAbonosMaquileros'
import AnticiposProveedoresCreate from "./views/CXP/AnticiposProveedores/AnticiposProveedoresCreate";
import ListadoAnticiposProveedores from "./views/CXP/AnticiposProveedores/ListadoAnticiposProveedores";
import ListadoFaltantesProveedores from "./views/CXP/AnticiposProveedores/ListadoFaltantesProveedores";


// Traspasos
import TraspasosSalidaCreate from './views/Inventarios/Traspasos/TraspasosSalidaCreate'
import ListadoTraspasosSalida from './views/Inventarios/Traspasos/ListadoTraspasosSalida'
import TraspasosEntradaCreate from './views/Inventarios/Traspasos/TraspasosEntradaCreate'
import ListadoTraspasosEntrada from './views/Inventarios/Traspasos/ListadoTraspasosEntrada'

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                <Route exact path ="/MenuUsuariosAdmin" component={ListadoUsuariosAdmin} />
                {/* Catalogos */}
                <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                <Route exact path ="/Planteles" component={ListadoPlanteles} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Ciclos" component={ListadoCiclos} />
                <Route exact path ="/PeriodosAdmin" component={ListadoPeriodosAdmin} />
                <Route exact path ="/Departamentos" component={ListadoDepartamentos} />
                <Route exact path ="/PeriodosAdminCreate" component={PeriodosAdminCreate} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                {/* Materiales */}
                <Route exact path ="/MenuMateriales" component={BotonesMateriales} />
                <Route exact path ="/Colores" component={ListadoColores} />
                <Route exact path ="/TiposTelas" component={ListadoTiposTelas} />
                <Route exact path ="/ListadoTelas" component={ListadoTelas} />
                <Route exact path ="/TelasCreate" component={TelasCreate} />
                <Route exact path ="/ListadoHabilitacion" component={ListadoHabilitacion} />
                <Route exact path ="/HabilitacionCreate" component={HabilitacionCreate} />
                <Route exact path ="/ListadoInventariosTelas" component={ListadoInventariosTelas} />
                <Route exact path ="/ListadoInventariosHabilitacion" component={ListadoInventariosHabilitacion} />
                {/* Articulos */}
                <Route exact path ="/MenuArticulos" component={BotonesArticulos} />
                <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                <Route exact path ="/ArticulosCreate" component={ArticulosCreate} />
                <Route exact path ="/Lineas" component={ListadoLineas} />
                <Route exact path ="/Temporadas" component={ListadoTemporadas} />
                <Route exact path ="/Colecciones" component={ListadoColecciones} />
                <Route exact path ="/ListadoTallas" component={ListadoTallas} />
                <Route exact path ="/TallasCreate" component={TallasCreate} />
                {/* InventariosTelas */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/ListadoInventarios" component={ListadoInventarios} />
                <Route exact path ="/ListadoDisponible" component={ListadoDisponible} />
                <Route exact path ="/ListadoDisponibleVendedor" component={ListadoDisponibleVendedor} />
                <Route exact path ="/ListadoSeguimiento" component={ListadoSeguimiento} />
                <Route exact path ="/ListadoPendRecibir" component={ListadoPendRecibir} />
                {/* Compras */}
                <Route exact path ="/MenuCompras" component={BotonesCompras} />
                <Route exact path ="/ListadoOrdenesCompra" component={ListadoOrdenesCompra} />
                <Route exact path ="/OrdenesCompraCreate" component={OrdenesCompraCreate} />
                <Route exact path ="/ListadoCompras" component={ListadoCompras} />
                <Route exact path ="/ComprasCreate" component={ComprasCreate} />
                <Route exact path ="/ListadoDevolucionesProveedores" component={ListadoDevolucionesProveedores} />
                <Route exact path ="/DevolucionesProveedoresCreate" component={DevolucionesProveedoresCreate} />
                {/* Produccion */}
                <Route exact path ="/MenuProduccion" component={BotonesProduccion} />
                <Route exact path ="/Maquileros" component={ListadoMaquileros} />
                <Route exact path ="/MaquilerosCreate" component={MaquilerosCreate} />
                <Route exact path ="/CatalogoProcesos" component={ListadoCatalogoProcesos} />
                <Route exact path ="/CatalogoProcesosCreate" component={CatalogoProcesosCreate} />
                <Route exact path ="/ListadoOrdenesCorte" component={ListadoOrdenesCorte} />
                <Route exact path ="/OrdenesCorteCreate" component={OrdenesCorteCreate} />
                <Route exact path ="/ListadoCortes" component={ListadoCortes} />
                <Route exact path ="/CortesCreate" component={CortesCreate} />
                <Route exact path ="/CortesPendientesRecibir" component={CortesPendientesRecibir} />
                <Route exact path ="/ListadoApartados" component={ListadoApartados} />
                <Route exact path ="/ApartadosCreate" component={ApartadosCreate} />
                {/* Ventas */}
                <Route exact path ="/MenuVentas" component={BotonesVentas} />
                <Route exact path ="/ListadoPedidos" component={ListadoPedidos} />
                <Route exact path ="/PedidosCreate" component={PedidosCreate} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />
                <Route exact path ="/ListadoPendienteSurtir" component={ListadoPendienteSurtir} />
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />              
                {/* Admin */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/Presupuestos" component={ListadoPresupuestos} />
                <Route exact path ="/PresupuestosCreate" component={PresupuestosCreate} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Nominas" component={ListadoNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/FaltasColaboradores" component={ListadoFaltasColaboradores} />
                <Route exact path ="/FaltasColaboradoresCreate" component={FaltasColaboradoresCreate} />
                <Route exact path ="/NominasCreate" component={NominasCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/Gastos" component={ListadoGastos} />
                <Route exact path ="/GastosCreate" component={GastosCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                <Route exact path ="/ListadoConceptosBonificaciones" component={ListadoConceptosBonificaciones} />
                <Route exact path ="/ListadoBonificaciones" component={ListadoBonificaciones} />
                <Route exact path ="/BonificacionesCreate" component={BonificacionesCreate} />
                {/* Clientes */}
                <Route exact path ="/MenuClientes" component={BotonesClientes} />
                <Route exact path ="/CatalogosFacturas" component={BotonesCatalogosFacturas} />
                <Route exact path ="/Clientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                <Route exact path ="/NotasCreate" component={FacturasCreate} />
                <Route exact path ="/EdoCtaClientes" component={ListadoFacturas} />
                <Route exact path ="/DetalleNotas" component={ListadoDetalleFacturas} />
                <Route exact path ="/Zonas" component={ListadoGrupos} />
                <Route exact path ="/AbonosNotasCreate" component={AbonosFacturasCreate} />
                <Route exact path ="/AbonosClientes" component={ListadoAbonosFacturas} />
                <Route exact path ="/ListadoCobranza" component={ListadoCobranza} />
                {/* Edo Cta Clientes  */}
                <Route exact path ="/EdoCtaVistaClientes" component={EdoCtaVistaClientes} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Config  */}
                <Route exact path ="/MenuConfiguraciones" component={Configuraciones} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/ListadoVentas" component={ListadoVentas} />
                <Route exact path ="/NotasCargoCreate" component={NotasCargoCreate} />
                <Route exact path ="/ListadoNotasCargo" component={ListadoNotasCargo} />
                <Route exact path ="/ListadoDevolucionesAdmin" component={ListadoDevolucionesAdmin} />
                {/* Reportes */}
                <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/CorteSemanal" component={CorteSemanal} />
                <Route exact path ="/MaquilasSemanal" component={MaquilasSemanal} />
                <Route exact path ="/EntradasSemanal" component={EntradasSemanal} />
                <Route exact path ="/EntregasSemanal" component={EntregasSemanal} />

                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/EstadoCuentaMaq" component={EstadoCuentaMaq} />
                <Route exact path ="/ListadoComprasCXP" component={ListadoComprasCXP} />
                <Route exact path ="/AbonosProveedoresCreate" component={AbonosProveedoresCreate} />
                <Route exact path ="/ListadoAbonosProveedores" component={ListadoAbonosProveedores} />
                <Route exact path ="/ListadoDevolucionesProveedoresAdmin" component={ListadoDevolucionesProveedoresAdmin} />
                <Route exact path ="/ListadoNotasCxP" component={ListadoNotasCxP} />
                <Route exact path ="/NotasCxPCreate" component={NotasCxPCreate} />
                <Route exact path ="/AbonosMaquilerosCreate" component={AbonosMaquilerosCreate} />
                <Route exact path ="/ListadoAbonosMaquileros" component={ListadoAbonosMaquileros} />
                <Route exact path ="/AnticiposProveedoresCreate" component={AnticiposProveedoresCreate} />
                <Route exact path ="/ListadoAnticiposProveedores" component={ListadoAnticiposProveedores} />
                <Route exact path ="/ListadoFaltantesProveedores" component={ListadoFaltantesProveedores} />
                 {/* Traspasos */}
                 <Route exact path ="/TraspasosSalidaCreate" component={TraspasosSalidaCreate} />
                <Route exact path ="/ListadoTraspasosSalida" component={ListadoTraspasosSalida} />
                <Route exact path ="/TraspasosEntradaCreate" component={TraspasosEntradaCreate} />
                <Route exact path ="/ListadoTraspasosEntrada" component={ListadoTraspasosEntrada} />
                
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
