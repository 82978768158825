import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPendienteSurtir() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_pedido, setNumeroPedido] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");

  const [inventarios, setInventarios] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [totalPrecio, setTotalPrecio] = useState(0);

  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");
  const [cliente, setCliente] = useState("");

  const [articulosPed, setArticulosPed] = useState([]);

  const [modalSurtirPedido, setModalSurtirPedido] = useState(false);
  const toggleSurtirPedido = () => setModalSurtirPedido(!modalSurtirPedido);

  const [modalAlmacen, setModalAlmacen] = useState(false);
  const toggleAlmacen = () => setModalAlmacen(!modalAlmacen);
  const [abrirSurtir, setAbrirSurtir] = useState(false);
  const [factura, setFactura] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");
  const [impuestos, setImpuestos] = useState(16);


  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      precio: 0,
      total: 0,
      pendiente_surtir: 0,
      cantPed: 0,
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    // setSelectedArea(user.areas);
    axios
      .get(`${URL_PEDIDOS}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_piezas: a.total_piezas,
              status: a.status,
              autorizado: a.autorizado,
              surtido: a.surtido,
              impuestos: a.impuestos,
              pedidoCliente: a.pedidoCliente,
              articulo: a.articulos[0].codigoCliente + " / " + a.articulos[0].codigo,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setPedidos(allPedidos);
        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  const ListaPedidos = comments.sort((a, b) =>
    a.idPedido > b.idPedido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.pedidoCliente,
          a.colaboradores,
          a.clientes,
          a.total_piezas,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          `Activo`,
          `Numero`,
          `Fecha`,
          `Ped. Cliente`,
          `Vendedor`,
          `Cliente`,
          `Cantidad`,
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Pedidos.pdf`);
        window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          PedidoCliente: a.pedidoCliente,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_piezas,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "PedidoCliente",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "PedidoCliente",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.pedidoCliente,
          a.colaboradores,
          a.clientes,
          a.total_piezas,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Ped. Cliente",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    pedidoCliente,
    colaboradores,
    idClientes,
    clientes,
    total_piezas,
    total_general,
    observaciones,
    numero,
    idArea,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            if(a.pendiente_surtir>0){
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              talla: a.tallas[0].name,
              color: a.colores[0].name
            }}
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        const data = dataFinal
          .sort(
            (a, b) =>
            a.color.localeCompare(b.color) ||
              a.talla.localeCompare(b.talla),
          )
        .map((a) => {
          return [
            a.articulos,
            a.color,
            a.talla,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.surtido),
            new Intl.NumberFormat("en-US").format(a.pendiente_surtir),
            // "$" + new Intl.NumberFormat("en-US").format(a.precio),
            // "$" + new Intl.NumberFormat("en-US").format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 115, 20, 55, 15);
        doc.text(`Pedido #: ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha: ${fecha}`, 20, 25);
        doc.text(`Cliente: ${clientes}`, 20, 30);
        doc.text(`Vendedor: ${colaboradores}`, 20, 35);
        doc.text(`Ped Cliente: ${pedidoCliente}`, 20, 40);
        doc.text(`${new Intl.NumberFormat("en-US").format(TK)} pzas`, 20, 45);
        doc.autoTable({
          head: [["Articulo", "Color", "Talla", "Cantidad", "Surtido", "Pendiente"]],
          body: dataPDFLimpia,
          startY: 50,
          foot: [
            [
              "",
              "",
              "",
              // "Total",
              // "$" + new Intl.NumberFormat("en-US").format(TG),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`Pedido-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Pedido Cliente", field: "pedidoCliente", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "PDF", field: "pdf", sortable: false },
    { name: "Surtir", field: "surtir", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_piezas.toString().includes(search) ||
          comment.pedidoCliente.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_piezas" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_piezas" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_piezas" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    let iva = 0;
    let TT = 0;

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    if (impuestos != 0) {
      iva = ((impuestos * ST) / 100).toFixed(2);
    } else {
      iva = 0;
    }
    TT = (((impuestos * ST) / 100) + ST).toFixed(2);

    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha,
            pedidos: selectedPedido,
            observaciones,
            user: user.id,
            clientes: cliente,
            areas: selectedArea,
            total_general: TT,
            saldo: TT,
            subTotal: ST,
            iva,
            impuestos,
            is_active:"Si",
            numeroFactura: factura,
            total_piezas: total
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_SURTIDOS,
                  {
                    surtidos: data.data._id,
                    inventarios: a.idInv,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    pedidos: selectedPedido,
                    articulosPedido: a.idArtPed,
                    tallas: a.idTalla,
                    colores: a.idColor,
                    fecha
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Surtido",
                          detalle: `${numero_pedido} ${selectedArea} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `${numero_pedido} ${selectedArea} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };


  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (
          (i.cantInv >= event.target.value 
            // && i.pendiente_surtir >= event.target.value
            ) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.total = parseFloat(event.target.value) * parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
          i.total = 0
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
    let cantPre = inputFields.map((c) => parseFloat(c.total));
    let TP = cantPre.reduce((t, total, index) => t + total, 0);
    setTotalPrecio(TP.toFixed(2));
  }

 
  function seleccionarPedido(id, numero, pedidoCliente, idClientes, observaciones) {
    setSelectedPedido(id);
    setNumeroPedido(numero);
    setPedidoCliente(pedidoCliente);
    setCliente(idClientes);
    setObservaciones(observaciones);
    toggleAlmacen();
  }

  function surtirPedido(){

    axios
      .get(`${URL_INVENTARIOS}/traspasos/${selectedArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir3/${selectedPedido}/${selectedArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPed = res.data;
        setArticulosPed(allArticulosPed);

        let arrayInputFields = allArticulosPed.map((a) => {
          return {
            id: uuidv4(),
            articulos: a.articulos,
            cantidad: 0,
            cantInv: a.cantInv,
            idInv: a.idInv,
            idArtPed: a.idArtPed,
            precio: a.precio,
            total: 0,
            pendiente_surtir: a.pendiente_surtir,
            cantPed: a.cantPed,
            idTalla:a.idTalla,
            talla:a.talla,
            idColor:a.idColor,
            color:a.color
          };
        });

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);

      })
      .catch((err) => {
        console.log(err);
      });
    toggleAlmacen(false)
    setAbrirSurtir(true)
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.surtidos_create  ? (
        <div className="card container col-12">
          {abrirSurtir ? (
            <div>
            <Row>
            <Form className="card container col-11" onSubmit={saveSurtidos}>
            <h3 align="center">Surtir Pedido {numero_pedido} Pedido Cliente {pedidoCliente}</h3>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
              </Col>
             
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <br />
            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cant Pedido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Pend Surtir</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Inventario</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields
            .sort(
              (a, b) =>
              a.color.localeCompare(b.color) ||
                a.talla.localeCompare(b.talla),
            )
            .map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      disabled
                      // onChange={(event) => {
                      //   BuscaArticulo(inputField.id, event);
                      // }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosPed
                        .sort((a, b) =>
                          a.articuloNombre > b.articuloNombre ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.articulos}>
                              {a.articuloNombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="string"
                      min="0"
                      step="any"
                      placeholder="Color"
                      value={inputField.color}
                      required
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="string"
                      min="0"
                      step="any"
                      placeholder="Talla"
                      value={inputField.talla}
                      required
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantPed"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantPed}
                      required
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="pendiente_surtir"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.pendiente_surtir}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantInv"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantInv}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      required
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">Totales {total} pzas. ${totalPrecio}</h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            <Row>
            {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
                </Row>
          </Form>
            </Row>
            </div>
          ):(
            <div>
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Pendiente Surtir</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Pendientes de Surtir</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                return (
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.fecha}</td>
                    <td>{a.pedidoCliente}</td>
                    <td>{a.articulo}</td>
                    <td>{a.clientes}</td>
                    <td>{a.colaboradores}</td>
                    <td>
                      {user.surtidos_create  ? (
                        <div>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.pedidoCliente,
                                a.colaboradores,
                                a.idClientes,
                                a.clientes,
                                a.total_piezas,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a.idArea,
                                a._id
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                        </div>
                      ) : undefined}
                    </td>
                    <td>
                      <Button
                        color="info"
                        id="Editar"
                        size="sm"
                        onClick={(e) =>
                          seleccionarPedido(
                            a._id,
                            a.numero,
                            a.pedidoCliente,
                            a.idClientes,
                            a.observaciones
                          )
                        }
                      >
                        <i class="fas fa-shipping-fast"></i>
                      </Button>
                    </td>
                    <td>
                      <StatusMultipleSurtido
                        idStatus={a._id}
                        status={a.surtido}
                        URL_STATUS={URL_PEDIDOS}
                        hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
              </div>

          )}

        </div>
      ) : undefined}
      <br />

      
     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      <Modal size="sm" isOpen={modalAlmacen} toggle={toggleAlmacen}>
                <ModalHeader toggle={toggleAlmacen}>
                  <h4>Selecciona un almacen</h4>
                </ModalHeader>
                <ModalBody>
                <Label className="mr-sm-2">Almacen</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Almacen</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if(a.name != "General"){
                        return (
                          <option value={a._id}>
                            {a.name}
                          </option>
                        );
                      }
                      })
                      }
                  </Input>
                  <Label className="mr-sm-2">Impuestos</Label>
                  <Input
                    type="select"
                    value={impuestos}
                    onChange={(e) => {
                      setImpuestos(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value={0}>No</option>
                    <option value={16}>Si</option>
                    
                  </Input>
                  <Label className="mr-sm-2">Factura</Label>
                  <Input
                    type="text"
                    value={factura}
                    onChange={(e) => {
                      setFactura(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={(e) => surtirPedido()}>
                    Continuar
                  </Button>
                </ModalFooter>
              </Modal>
      {loader}
    </>
  );
}

export default ListadoPendienteSurtir;
