import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesReportes() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_reportes ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/CorteSemanal" className="botonesMenu" color="success">
              <i class="fas fa-chart-line fa-7x"></i>
              <br />
              <br />
              Corte Semanal
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-chart-line fa-7x"></i>
          <br />
          <br />
          Corte Semanal
        </Button> }
          </Col>

        <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/MaquilasSemanal" className="botonesMenu" color="success">
              <i class="fas fa-chart-line fa-7x"></i>
              <br />
              <br />
              Maquilas Semanal
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-chart-line fa-7x"></i>
          <br />
          <br />
          Maquilas Semanal
        </Button> }
          </Col>

        <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/EntradasSemanal" className="botonesMenu" color="success">
              <i class="fas fa-chart-line fa-7x"></i>
              <br />
              <br />
              Entradas Semanal
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-chart-line fa-7x"></i>
          <br />
          <br />
          Entradas Semanal
        </Button> }
          </Col>
          
          </Row>

          <br />
          <br />
          <Row >
          <Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/EntregasSemanal" className="botonesMenu" color="success">
              <i class="fas fa-chart-line fa-7x"></i>
              <br />
              <br />
              Entregas Semanal
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-chart-line fa-7x"></i>
          <br />
          <br />
          Entregas Semanal
        </Button> }
          </Col>
          
          </Row>


      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesReportes;
